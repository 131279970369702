import { ActionFunctionArgs } from "react-router";

import { setLanguage } from "~/api";

export async function action({ request }: ActionFunctionArgs) {
	let formData = await request.formData();
	let language = formData.get("language");

	if (!language || typeof language !== "string") {
		return "Language is required";
	}

	await setLanguage(language === "en-GB" ? "en-GB" : "nb-NO");

	return null;
}
